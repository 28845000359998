import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  Paper,
  Box,
  TableSortLabel,
} from '@mui/material';
import { getComparator, stableSort, getNestedValue } from '../utils/TableUtil';

const EnhancedTableHead = ({ order, orderBy, onRequestSort, headCells }) => {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className="cfsrow">
      <TableRow className="cfsrow">
        {headCells.map((headCell) => (
          <TableCell
            className='cfscell'
            key={headCell.id}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const EnhancedTable = ({ data, headCells }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(headCells[0].id);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%' }}>
        <TableContainer>
          <Table className="cfstable" aria-labelledby="tableTitle">
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {stableSort(data, getComparator(order, orderBy)).map((row, index) => (
                <TableRow key={index}>
                  {headCells.map((headCell) => (
                    <TableCell className={`cfscell-basic ${headCell.className || ""}`} key={headCell.id} align={'center'}>
                      {Array.isArray(getNestedValue(row, headCell.id)) 
                        ? getNestedValue(row, headCell.id).join(', ') 
                        : getNestedValue(row, headCell.id)}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default EnhancedTable;
