import { Label, SubmitButton, BoxContainer } from "../components";
import GoogleSearch from "../assets/images/google.png";
import SendIcon from "@mui/icons-material/Send";
export const GoogleReviewPage = (props) => {
  const { onSubmit, hasGoogle, button, logo, next, prev } = props;

  const onClick = () => {
    window.open(hasGoogle);
    next();
  };

  return (
    <BoxContainer>
      <br />
      <div style={{ textAlign: "center" }}>
        {logo !== null && logo !== undefined && logo !== "" && (
          <img src={logo} style={{ width: "350px" }} alt="logo" />
        )}
      </div>
      <br />
      {/* <br />
      <Label text="We appreciate your feedback and 5-star rating! Our team aims to make our customers happy. Thank you. 🙂" /> */}
      <br />
      {hasGoogle && (
        <>
          <Label text="Please leave a comment on Google, tell everyone what you liked.👇" />
          <SubmitButton onClick={onClick} color={button}>
            <img src={GoogleSearch} style={{ height: "25px" }} alt="Logo" />
            &nbsp;&nbsp;Review on Google
          </SubmitButton>
          <SubmitButton onClick={prev} color="lightgray">
            <SendIcon
              sx={{ transform: "rotate(180deg)" }}
              style={{ color: "black" }}
            />
          </SubmitButton>
        </>
      )}
    </BoxContainer>
  );
};
