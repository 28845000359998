import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { companyDetail, exists } from "../redux/actions";
import {
  BoxContainer,
  InputBox,
  PageBox,
  PageContainer,
  SubmitButton,
} from "../components";
import { Checkbox, Stack } from "@mui/material";

export const SelectPage = () => {
  const params = useParams();

  const [logo, setLogo] = useState("");
  const [button, setButton] = useState("");
  const [menu, setMenu] = useState([]);
  const [exist, setExist] = useState(0);
  const [legal, setLegal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [autoforward, setAutoForward] = useState(-1);

  useEffect(() => {
    exists(params.id, (response) => {
      const { data, result } = response;
      console.log(response, "selectpage.jsx");
      if (result) {
        setLogo(data.logo);
        setButton(data.button);
        let newMenu = data.menu ?? [];
        if (newMenu.length === 5) {
          newMenu.push(
            { visible: false, url: "/", title: "Website", visits: 0 },
            { visible: false, url: "/", title: "Apple App Store", visits: 0 },
            { visible: false, url: "/", title: "Android App Store", visits: 0 },
            { visible: false, url: "/", title: "Google Reviews", visits: 0 },
            { visible: false, url: "/", title: "Yelp", visits: 0 }
          );
        }
        setMenu(newMenu);
        setAutoForward(data.autoforward);

        let cookie = decodeURIComponent(document.cookie)
          .split(";")
          .find((c) => c.trim().startsWith("lfologin="));
        let isAdmin = false;
        if (cookie !== null && cookie !== undefined && cookie !== "") {
          cookie = cookie.trim().slice(9);
          if (cookie === "Leavefeedback2024$") isAdmin = true;
          else {
            isAdmin = data.managers.some((manager) => manager.email === cookie);
          }
        }
        if (isAdmin === false && data.autoforward !== -1) {
          redirect(data.menu, data.autoforward);
        } else {
          setLegal(isAdmin);
          setExist(2);
        }
      } else setExist(1);
    });
  }, [params.id]);

  const getExactPath = (path) => {
    const preUrl = path.startsWith("/") ? `/${params.id}` : "";
    return preUrl + path;
  };

  const handleEdit = () => {
    if (editing) {
      companyDetail({ company: params.id, menu, autoforward }, () => {});
    }
    setEditing(!editing);
  };

  const handleChange = (index, key, value) => {
    if (index === 0) return;
    const newMenu = [...menu];
    newMenu[index] = {
      ...newMenu[index],
      [key]: value,
    };
    setMenu(newMenu);
  };

  const redirect = (menu, index) => {
    if (menu[index].url.startsWith("/")) {
      const path = getExactPath(menu[index].url);
      document.location.href = path;
    } else {
      let newMenu = menu;
      newMenu[index] = {
        ...newMenu[index],
        visits: (newMenu[index]?.visits ?? 0) + 1,
      };
      companyDetail(
        {
          company: params.id,
          menu: newMenu,
        },
        () => {
          const path = getExactPath(menu[index].url);
          document.location.href = path;
        }
      );
    }
  };

  return (
    <PageContainer>
      <PageBox>
        {exist === 2 && (
          <BoxContainer>
            <div style={{ textAlign: "center" }}>
              {logo !== null && logo !== undefined && logo !== "" && (
                <img src={logo} style={{ width: "286px" }} alt="logo" />
              )}
              {legal && (
                <p>
                  <u onClick={handleEdit} style={{ cursor: "pointer" }}>
                    {editing ? "Save changes" : "Edit Page"}
                  </u>
                </p>
              )}
            </div>
            {menu.map((btn, index) => (
              <div key={index} style={{width: "286px"}}>
                {(btn.visible || editing) && (
                  <SubmitButton
                    color={button}
                    key={index}
                    onClick={() => redirect(menu, index)}
                  >
                    {btn.title}
                  </SubmitButton>
                )}
                {editing && (
                  <>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          minWidth: 130,
                          maxWidth: 130,
                          alignContent: "center",
                        }}
                      >
                        Button Title:
                      </span>
                      <InputBox
                        value={btn.title}
                        func={(str) => handleChange(index, "title", str)}
                      ></InputBox>
                    </div>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          minWidth: 130,
                          maxWidth: 130,
                          alignContent: "center",
                        }}
                      >
                        Destination:
                      </span>
                      <InputBox
                        value={btn.url}
                        func={(str) => handleChange(index, "url", str)}
                      ></InputBox>
                    </div>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          minWidth: 120,
                          maxWidth: 120,
                          alignContent: "center",
                        }}
                      >
                        Show Button:
                      </span>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        width="-webkit-fill-available"
                      >
                        <Checkbox
                          checked={btn.visible}
                          sx={{ paddingBottom: 0 }}
                          onChange={(e) =>
                            handleChange(index, "visible", e.target.checked)
                          }
                        />
                        <b style={{ alignContent: "center" }}>
                          Visits: {btn?.visits ?? 0}
                        </b>
                      </Stack>
                    </div>
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          minWidth: 120,
                          maxWidth: 120,
                          alignContent: "center",
                        }}
                      >
                        Forward:
                      </span>
                      <Checkbox
                        checked={index === autoforward}
                        sx={{ paddingTop: 0 }}
                        onChange={(e) => {
                          if (e.target.checked === true) setAutoForward(index);
                          else setAutoForward(-1);
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            ))}
          </BoxContainer>
        )}
      </PageBox>
    </PageContainer>
  );
};
