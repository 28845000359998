import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";

export const RedirectSelector = (props) => {
  const {value, setValue, title, labels, values} = props;

  return (
    <>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label" sx={{fontSize: 18, fontWeight: 700, color: "black"}}>
          {title}
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {values.map((value, index) => 
            <FormControlLabel
              key={index}
              value={index}
              control={<Radio sx={{padding: 0}}/>}
              label={labels[index]}
              sx={{alignItems: "flex-start !important", margin: 1}}
            />
          )}
        </RadioGroup>
      </FormControl>
    </>
  );
};
