import { useEffect, useState } from "react";
import { detailReviewRating } from "../constants";
import { companyDetail, modify, send } from "../redux/actions";
import {
  Homepage,
  GoogleReviewPage,
  ReviewPage,
  InfoPage,
  ConfirmPage,
  FriendPage,
} from "./";
import { PageContainer, PageBox } from "../components";
import { useNavigate, useParams } from "react-router-dom";
import { exists } from "../redux/actions/user";

export const ClientPage = () => {
  const navigate = useNavigate();

  const params = useParams();

  const [exist, setExist] = useState(0);
  const [google, setGoogle] = useState("");
  const [button, setButton] = useState("");
  const [logo, setLogo] = useState("");
  const [star, setStar] = useState("");
  const [name, setName] = useState("");

  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");

  const [drating, setDRating] = useState(detailReviewRating);
  const [dreview, setDReview] = useState("");

  const [infoID, setInfoID] = useState("");
  const [sign, setSign] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [address, setAddress] = useState("");
  const [redeem, setRedeem] = useState("");
  const [expire, setExpire] = useState("");
  const values = [
    [0, 2, 3, 4],
    [0, 1, 3, 4],
    [0, 5, 3, 4],
    [0, 2, 1, 3, 4],
    [0, 2, 5, 3, 4],
  ];
  const [way, setWay] = useState([0, 2, 3, 4]);
  const [step, setStep] = useState(0);
  const [four, setFour] = useState([2]);
  const [five, setFive] = useState([2]);
  const [menu, setMenu] = useState([]);

  const onSubmitReview = () => {
    send(
      {
        company: params.id,
        rating: rating,
        review: review,
        review_score: drating,
        review_text: dreview,
      },
      (id) => {
        setInfoID(id);
      }
    );
  };

  const onSubmitConfirm = () => {
    handleNext();
  };

  const handleSetWay = (star) => {
    console.log(star);
    console.log(four, five);
    setRating(star);
    if (star === 4) setWay(four);
    else if (star === 5) setWay(five);
    else setWay(values[0]);
  };

  const onSubmitInfo = () => {
    modify(
      {
        id: infoID,
        name: sign,
        email: email,
        phone: phone,
      },
      () => {}
    );
  };

  const handleNext = () => {
    if (step === 0 && way[1] % 4 === 1) {
      onSubmitReview();
    }
    setStep((step + 1) % way.length);
  };

  const handlePrev = () => {
    setStep(step === 0 ? 0 : step - 1);
  };

  useEffect(() => {
    exists(params.id, (response) => {
      const { result, data } = response;
      console.log(response);
      if (result) {
        setGoogle(data.google);
        setLogo(data.logo);
        setStar(data.star);
        setButton(data.button);
        setName(data.display);
        setAddress(data.address);
        setMessage(data.message);
        setRedeem(data.redeem);
        setExpire(data.expire);
        setFour(values[data.four ?? 0]);
        setFive(values[data.five ?? 0]);
        setMenu(data.menu);
        setExist(2);
      } else setExist(1);
    });
  }, [params.id]);

  useEffect(() => {
    if (exist === 1) navigate("/" + params.id + "/admin");
    else if (exist === 2) {
      let newMenu = menu;
      newMenu[0] = {
        ...newMenu[0],
        visits: (newMenu[0]?.visits ?? 0) + 1,
      };
      companyDetail(
        {
          company: params.id,
          menu: newMenu,
        },
        () => {}
      );
    }
  }, [exist, navigate, params.id]);

  return (
    <PageContainer>
      <PageBox>
        {way[step] === 0 && exist === 2 && (
          <Homepage
            rating={rating}
            setRating={handleSetWay}
            review={review}
            setReview={setReview}
            logo={logo}
            star={star}
            button={button}
            next={handleNext}
            prev={handlePrev}
          />
        )}
        {way[step] === 1 && exist === 2 && (
          <GoogleReviewPage
            hasGoogle={google}
            button={button}
            logo={logo}
            next={handleNext}
            prev={handlePrev}
          />
        )}
        {way[step] === 2 && exist === 2 && (
          <ReviewPage
            logo={logo}
            rating={drating}
            setRating={setDRating}
            review={dreview}
            setReview={setDReview}
            onSubmit={onSubmitReview}
            button={button}
            next={handleNext}
            prev={handlePrev}
          />
        )}
        {way[step] === 3 && exist === 2 && (
          <InfoPage
            logo={logo}
            name={sign}
            setName={setSign}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            onSubmit={onSubmitInfo}
            button={button}
            next={handleNext}
            prev={handlePrev}
          />
        )}
        {way[step] === 4 && exist === 2 && (
          <ConfirmPage
            onSubmit={onSubmitConfirm}
            logo={logo}
            next={handleNext}
            prev={handlePrev}
          />
        )}
        {way[step] === 5 && exist === 2 && (
          <FriendPage
            company={name}
            star={rating}
            message={message}
            redeem={redeem}
            expire={expire}
            address={address}
            id={params?.id ?? 0}
          />
        )}
      </PageBox>
    </PageContainer>
  );
};
