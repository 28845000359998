import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { companyDetail, exists } from "../redux/actions";
import { BoxContainer, PageBox, PageContainer } from "../components";
import { MenuPage } from "./menupage";
import { DealPage } from "./dealpage";
import { JobPage } from "./jobpage";
import { GamePage } from "./gamepage";

export const RedirectPage = () => {
  const params = useParams();
  const [data, setData] = useState({});
  const [ind, setIndex] = useState(-1);
  const [exist, setExist] = useState(0);

  useEffect(() => {
    if (params.id && params.page) {
      exists(
        params.id,
        (response) => {
          const {data, result} = response;
          console.log(response, "redirectpage.jsx");
          if(result) {
            setData(data);
            const index = data.menu.findIndex((menu) => menu.url === `/${params.page}` || menu.url === `${params.page}`);
            setIndex(index);
            console.log(index);
            setExist(2);
          } else {
            setExist(1);
          }
        },
      );
    }
  }, [params.id, params.page]);

  useEffect(() => {
    if(ind >= 0) {
      let newMenu = data.menu;
      newMenu[ind] = {
        ...(newMenu[ind]),
        visits: (newMenu[ind]?.visits ?? 0) + 1
      };
      companyDetail(
        {
          company: params.id,
          menu: newMenu
        },
        () => {}
      );
    }
  }, [ind])

  return (
    <PageContainer>
      <PageBox>
        <BoxContainer>
          {exist === 2 && (
            <>
              <div style={{ textAlign: "center" }}>
                <img src={data.logo} style={{ width: "350px" }} alt="logo" />
              </div>
              {ind === 1 && <MenuPage data={data} />}
              {ind === 2 && <DealPage data={data} />}
              {ind === 3 && <GamePage data={data} />}
              {ind === 4 && <JobPage data={data} />}
            </>
          )}
        </BoxContainer>
      </PageBox>
    </PageContainer>
  );
};
