import { useEffect, useState } from "react";
import qs from "qs";
import { geocodeByAddress } from "react-google-places-autocomplete";
import axios from "axios";

import { parseLocation, parseState } from "../../constants/CompaniesSearch";

import { LocationSearch } from "./LocationSearch";

const GOOGLE_PLACES_API_URL = "https://places.googleapis.com/v1/places";

export const SearchBar = ({
  setAddress,
  setGoogleId,
  setGooglePlaceReviews,
  setDisplay,
}) => {
  const [selectedLocation, setSelectedLocation] = useState();
  const [refinedAddress, setRefinedAddress] = useState("");

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   const params = {};
  //   if (selectedLocation) {
  //     const results = await geocodeByAddress(selectedLocation?.label);
  //     const addressComponents = results[0].address_components;
  //     const zipCode = parseLocation(addressComponents, 'postal_code');
  //     const cityName = parseLocation(addressComponents, 'locality');
  //     const stateName = parseState(addressComponents, 'administrative_area_level_1');
  //     const address = {};
  //     if (cityName !== '') {
  //       address.city = cityName;
  //     }
  //     if (stateName || zipCode) {
  //       address.stateOrZipCode = stateName || zipCode;
  //     }
  //     address.full_location = selectedLocation?.label;
  //     address.id = 0;

  //     params.addresses = encodeURI(JSON.stringify([address]));
  //   }
  //   let input = qs.stringify(params);

  //   const encodedParams = input.split("=")[1];

  //   const decodedParams = decodeURIComponent(encodedParams);
  //   const finalDecodedParams = decodeURIComponent(decodedParams);

  //   console.log(finalDecodedParams);

  //   const parsedArray = JSON.parse(finalDecodedParams);

  //   if (parsedArray.length > 0) {
  //     const fullLocation = parsedArray[0].full_location;
  //     setRefinedAddress(`${fullLocation}`);
  //     console.log(fullLocation);
  //   }

  //   console.log('refinedAddress: ', refinedAddress);
  // };

  useEffect(() => {
    if (!selectedLocation) {
      return;
    }

    const fetch = async () => {
      const params = {};
      const address = {};

      const results = await geocodeByAddress(selectedLocation?.label);
      const addressComponents = results[0].address_components;
      const zipCode = parseLocation(addressComponents, "postal_code");
      const cityName = parseLocation(addressComponents, "locality");
      const stateName = parseState(
        addressComponents,
        "administrative_area_level_1"
      );

      if (cityName !== "") {
        address.city = cityName;
      }

      if (stateName || zipCode) {
        address.stateOrZipCode = stateName || zipCode;
      }

      address.full_location = selectedLocation?.label;
      address.id = 0;
      params.addresses = encodeURI(JSON.stringify([address]));

      let input = qs.stringify(params);
      const encodedParams = input.split("=")[1];
      const decodedParams = decodeURIComponent(encodedParams);
      const finalDecodedParams = decodeURIComponent(decodedParams);

      const parsedArray = JSON.parse(finalDecodedParams);

      if (parsedArray.length > 0) {
        const fullLocation = parsedArray[0].full_location;
        setRefinedAddress(`${fullLocation}`);
        console.log(fullLocation);
      }
    };

    fetch();
  }, [selectedLocation]);

  useEffect(() => {
    if (!refinedAddress) {
      return;
    }

    async function monitorNetworkRequests() {
      const results = await geocodeByAddress(refinedAddress);
      const placeId = results[0].place_id;
      const formattedAddress = results[0].formatted_address;

      const {
        data: { userRatingCount, rating, displayName },
      } = await axios.get(
        `${GOOGLE_PLACES_API_URL}/${placeId}?fields=*&key=${process.env.REACT_APP_GOOGLE_API}`
      );

      setGooglePlaceReviews({ rating, ratingCount: userRatingCount });
      setAddress(formattedAddress.replace(/, (US|USA)$/, ''));
      setGoogleId(placeId);
      setDisplay(displayName.text);
    }

    monitorNetworkRequests();

    return () => {
      window.removeEventListener("fetch", monitorNetworkRequests);
    };
  }, [refinedAddress, setAddress, setGoogleId, setGooglePlaceReviews]);

  return (
    <form autoComplete="off" className="w-[90%] mx-auto search-bar">
      <div className="flex items-center relative  w-full p-[0.5rem] pe-[0.37rem] text-sm text-gray-900 rounded-[3.75rem] bg-white cursor-text">
        <LocationSearch setSelectedLocation={setSelectedLocation} />
      </div>
    </form>
  );
};
