import { Typography } from "@mui/material"

export const Label = (props) => {
  const { text, lineHeight, fontWeight, fontSize, children, letterSpacing } = props;

  return (
    <Typography
      sx={{
        fontSize: fontSize||18,
        fontFamily: "Arial Light",
        fontWeight: fontWeight || 800,
        mb: 2,
        letterSpacing: letterSpacing || 1,
        mt: 3,
        lineHeight: lineHeight,
      }}
    >
      {text/*.split('\n').map((val, index) => <p key={index}>{val}</p>)}*/}
      {children}
    </Typography>
  )
}