import { Badge, IconButton } from "@mui/material";
import { UploadButton, ClickButton, Label, ConfirmationDialog } from "./";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { uploadResume } from "../redux/actions";
import { useState } from "react";

const ManagerJobBox = (props) => {
  const { handleDelete, children } = props;

  return (
    <Badge
      badgeContent={
        <IconButton
          onClick={handleDelete}
          sx={{
            width: "24px",
            height: "24px",
            background: "#ffffff",
            color: "#7f7f7f",
            right: "4px",
            top: "4px",
          }}
        >
          <CancelOutlinedIcon />
        </IconButton>
      }
      sx={{
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
        ".MuiBadge-badge": {
          height: "auto",
          padding: "0px",
          transform: "none !important",
        },
      }}
    >
      <div>{children}</div>
    </Badge>
  );
};

const CustomerJobBox = (props) => {
  const { handleUpload, uploaded, loading, children } = props;
  const [file, setFile] = useState(undefined);

  const handleSelected = (event) => {
    const files = event.target.files;
    if (files) {
      if (files.length === 0) return;
      setFile(files[0]);
    }
  };

  const handleFileUpload = () => {
    handleUpload(file);
  };

  return (
    <>
      {children}
      {uploaded && (
        <>
          <UploadButton
            text={file ? "✔️ RESUME" : "Select resume"}
            onChange={handleSelected}
            sx={{
              background: "lightgray",
              color: "black",
              ":hover": {
                backgroundColor: "darkgray",
              },
            }}
          />{" "}
          <ClickButton
            startIcon={<CloudUploadIcon />}
            text="Upload"
            onClick={handleFileUpload}
            loading={loading}
          />
        </>
      )}
    </>
  );
};

export const JobBox = (props) => {
  const { isManager, handleDelete, id, title, description } = props;
  const [loading, setLoading] = useState(false);
  const [uploaded, setUploaded] = useState(true);
  const [text, setText] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = (text) => {
    setText(text);
    setOpen(true);
  };

  const handleUpload = (file) => {
    if (file === undefined) {
      handleOpen("Please select a resume, then upload.");
    } else {
      setLoading(true);
      const formData = new FormData();
      formData.append("resume", file);
      formData.append("job", id);

      uploadResume(formData, (data) => {
        console.log(data);
        setLoading(false);
        if (data.data.result) {
          handleOpen("Your resume was successfully uploaded.");
          setUploaded(false);
        } else handleOpen(data.data.message);
      });
    }
  };

  const element = (
    <CustomerJobBox
      handleUpload={handleUpload}
      loading={loading}
      uploaded={uploaded}
    >
      <Label text={title} />
      <Label fontWeight={400} text={description} />
      <ConfirmationDialog open={open} onClose={handleClose}>
        <Label fontWeight={400} text={text} />
      </ConfirmationDialog>
    </CustomerJobBox>
  );

  if (isManager) {
    return <ManagerJobBox handleDelete={handleDelete}>{element}</ManagerJobBox>;
  } else {
    return element;
  }
};
