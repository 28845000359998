import { detailReviewKey } from "../constants";
import SendIcon from "@mui/icons-material/Send";
import {
  Label,
  FaceRating,
  SubmitButton,
  InputBox,
  BoxContainer,
} from "../components";

export const ReviewPage = (props) => {
  const {
    rating,
    setRating,
    review,
    setReview,
    onSubmit,
    button,
    logo,
    next,
    prev,
  } = props;

  const onClick = () => {
    if (
      rating.cleanliness &&
      rating.friendliness &&
      rating.price &&
      rating.quality &&
      rating.wait
    ) {
      onSubmit();
      next();
    } else {
      alert("Please rate the services.");
    }
  };

  return (
    <BoxContainer>
      <br />
      <div style={{ textAlign: "center" }}>
        {logo !== null && logo !== undefined && logo !== "" && (
          <img src={logo} style={{ width: "350px" }} alt="logo" />
        )}
        <br />
        <br />
        <Label text="How was our service?" lineHeight={0} />
        <Label
          text="(Pick a reaction for each)"
          lineHeight={0}
          fontSize={15}
          fontWeight={500}
        />
      </div>

      {detailReviewKey.map((val) => (
        <FaceRating
          text={val.text}
          rkey={val.rkey}
          value={rating}
          func={setRating}
        />
      ))}

      <div style={{ textAlign: "center" }}>
        <Label text="What can we improve?" />
      </div>
      <InputBox
        value={review}
        func={setReview}
        multiline
        placeholder="Additional comments or suggestions"
      />

      <SubmitButton onClick={onClick} color={button}>
        Submit
      </SubmitButton>
      <SubmitButton onClick={prev} color="white">
        <SendIcon
          sx={{ transform: "rotate(180deg)" }}
          style={{ color: "gray" }}
        />
      </SubmitButton>
    </BoxContainer>
  );
};
