import axios from "../axiosConfig";

export const uploadResume = (params, callback) => {
  axios
    .post('/resume/upload', params, {
      headers: { "Content-Type": "multipart/form-data" },
    })
    .then( callback )
    .catch( err => {
      callback({data: {
        result: false,
        message: "Error :" + err
      }})
    });
};