import { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { Box, Stack, TextField, Button, Typography, Autocomplete, CircularProgress } from '@mui/material';
import { CSVLink } from "react-csv";
import { googleApiPlaceTypes } from "../constants";
import "./../App.css";
import EnhancedTable from '../components/EnhancedTable';

const GOOGLE_API_PLACES_SEARCH_URL = "https://places.googleapis.com/v1/places:searchText";
const GOOGLE_API_PLACES_CONFIG = {
  headers: {
    'Content-Type': 'application/json',
    'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_API,
    'X-Goog-FieldMask': 'places.displayName,places.formattedAddress,places.rating,places.nationalPhoneNumber,places.websiteUri,places.types,places.userRatingCount,nextPageToken'
  }
};

const headCells = [
  {
    id: 'displayName.text',
    numeric: false,
    label: 'Name',
  },
  {
    id: 'formattedAddress',
    numeric: true,
    label: 'Address',
  },
  {
    id: 'rating',
    numeric: true,
    label: 'Rating',
  },
  {
    id: 'userRatingCount',
    numeric: true,
    label: 'Total Reviews',
  },
  {
    id: 'nationalPhoneNumber',
    numeric: true,
    label: 'Phone No#',
    className: 'auto-width-cell'
  },
  {
    id: 'websiteUri',
    numeric: true,
    label: 'Website',
    className: 'website-uri',
  },
  {
    id: 'types',
    numeric: true,
    label: 'Types',
    className: 'website-uri',
  },
];

export const SalesPage = () => {
  const [zipCode, setZipCode] = useState('');
  const [type, setType] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [minRating, setMinRating] = useState('');
  const [results, setResults] = useState([]);
  const [nextPageToken, setNextPageToken] = useState('');
  const [allSearchedPlaces, setAllSearchedPlaces] = useState([]);
  const [csvLoading, setCSVLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (csvLoading || !allSearchedPlaces.length) { return; }

    ref.current.link.click();
    setAllSearchedPlaces([]);
  }, [csvLoading, allSearchedPlaces]);

  async function allSearchesCSVDownload() {
    setCSVLoading(true);
    axios.get('/place')
      .then((response) => {
        setAllSearchedPlaces(response.data.places);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
    setCSVLoading(false);
  };

  const createSearchPlaces = async (searchData, places) => {
    await axios.post('/place/searched-places', { searchData: searchData, places: places })
      .catch(error => {
        console.error('Error creating searched places data :', error);
      });
  }

  const handleSubmit = async (e, nextPage = false) => {
    e.preventDefault();

    const requestData = {
      textQuery: `${searchQuery} ${zipCode}`,
      includedType: type,
    };

    if (minRating) {
      requestData.minRating = minRating;
    }

    if (nextPage && nextPageToken) {
      requestData.pageToken = nextPageToken;
    }

    axios.post(GOOGLE_API_PLACES_SEARCH_URL, requestData, GOOGLE_API_PLACES_CONFIG)
      .then(response => {
        const responseResults = response.data.places || [];
        setSearchPerformed(true);
        setResults(nextPage ? [...results, ...responseResults] : responseResults);
        setNextPageToken(response.data.nextPageToken);
        createSearchPlaces({...requestData, zipCode: zipCode }, results);
      })
      .catch(error => {
        console.error('Error: fetching results from places api', error);
      });
  };

  const transformedHeadCells = headCells.map(({ id, label }) => ({ key: id, label: label }));

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: '50px',
          flexDirection: 'column'
        }}
      >
        <Stack
          component="form"
          sx={{
            width: '300px',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#fff',
          }}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <TextField
            fullWidth
            label="Zip Code"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
          />
          <TextField
            fullWidth
            label="Min Rating"
            type="number"
            inputProps={{ step: 0.1, max: 5, min: 0 }}
            value={minRating}
            onChange={(e) => setMinRating(e.target.value)}
          />
          <Autocomplete
            fullWidth
            disablePortal
            id="combo-box-type"
            options={googleApiPlaceTypes}
            renderInput={(params) => <TextField {...params} label="Type" />}
            value={type}
            getOptionLabel={(option) => option || ""}
            onChange={(e, value) => setType(value)}
          />
          <TextField
            fullWidth
            label="Extra"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <Box sx={{ textAlign: 'center' }}>
            <Button type="submit" onClick={handleSubmit} variant="contained" color="primary" sx={{ margin: '10px 0' }}>Search</Button>
          </Box>
          <Button onClick={allSearchesCSVDownload} variant="contained" color="primary" disabled={csvLoading}>
            {csvLoading ? <CircularProgress /> : "Download All"}
          </Button>
          <CSVLink data={allSearchedPlaces} ref={ref} headers={transformedHeadCells} filename="data.csv" />
        </Stack>
       
        {searchPerformed && results.length === 0 && (
          <Typography sx={{ textAlign: 'center', marginTop: '20px' }}>No results found</Typography>
        )}
      </Box>
      
      {results.length > 0 && (
        <Box sx={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}> {/* Center the table and add padding */}
          <Box sx={{ padding: 2, display: 'flex', justifyContent: 'center', gap: 2 }}>
            <CSVLink
              data={results}
              headers={transformedHeadCells}
              style={{
                textDecoration: 'none',
              }}
            >
              <Button variant="contained" color="primary">Download Screen</Button>
            </CSVLink>
          </Box>
          <EnhancedTable data={results} headCells={headCells} />
          {nextPageToken && (
            <Box sx={{ textAlign: 'center', marginTop: '10px' }}>
              <Button onClick={(e) => handleSubmit(e, true)} variant="contained" color="primary">Load More</Button>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};
