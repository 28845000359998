import {
  InputBox,
  InputBoxPhone,
  Label,
  SubmitButton,
  BoxContainer,
} from "../components";
import SendIcon from "@mui/icons-material/Send";

export const InfoPage = (props) => {
  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    onSubmit,
    button,
    logo,
    next,
    prev,
  } = props;
  const onClick = () => {
    onSubmit();
    next();
  };

  return (
    <BoxContainer>
      <br />
      <div style={{ textAlign: "center" }}>
        {logo !== null && logo !== undefined && logo !== "" && (
          <img src={logo} style={{ width: "350px" }} alt="logo" />
        )}
      </div>
      <br />
      <br />
      <Label text="Leave your contact info for follow ups. (optional)" />
      <Label text="" />

      <Label text="Name" />
      <InputBox value={name} func={setName} regexp={/^[a-zA-Z ]+$/} />

      <Label text="Email" />
      <InputBox
        value={email}
        func={setEmail}
        regexp={/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/}
      />

      <Label text="Phone" />
      <InputBoxPhone
        value={phone}
        func={setPhone}
        regexp={/^[+\-() \d]{10,}$/}
      />

      <SubmitButton onClick={onClick} color={button}>
        Done
      </SubmitButton>
      <SubmitButton onClick={prev} color="lightgray">
        <SendIcon
          sx={{ transform: "rotate(180deg)" }}
          style={{ color: "black" }}
        />
      </SubmitButton>
    </BoxContainer>
  );
};
