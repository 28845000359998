import { useRef, useState } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Typography from '@mui/material/Typography';

export const LocationSearch = ({ setSelectedLocation, selectedLocation }) => {
  const [showOptions, setShowOptions] = useState(false);
  const inputRef = useRef(null);
  const handleInputChange = (input) => {
    setShowOptions(!!input);
  };

  const customStyles = {
    singleValue: (provided) => ({
      ...provided,
      padding: '14px',
    }),
    control: (provided, state) => ({
      ...provided,
      border: '1px solid black',
      borderRadius: '4px',
      transition: 'border-color 0.2s',
      '&:focus-within': {
        borderColor: '#3498db',
        outline: 'none',
      },
    }),
  };

  return (
    <div className="relative md:w-[31%] xs:w-[33%] ps-[2.56rem] xs:ps-[1.18rem]">
      <Typography fontSize={"18px"} lineHeight={"1"} gutterBottom>
        <strong>Location Search</strong>
      </Typography>
      <GooglePlacesAutocomplete
        apiKey={process.env.NEXT_PUBLIC_GOOGLE_API}
        onLoadFailed={(error) => console.error('Could not inject Google script', error)}
        selectProps={{
          id: 'selectbox',
          instanceId: 'selectbox',
          className: 'react-select-container',
          classNamePrefix: 'react-select',
          selectedLocation,
          onInputChange: handleInputChange,
          onChange: setSelectedLocation,
          placeholder: 'Type to Search',
          ref: inputRef,
          styles: {
            ...customStyles,
            loadingIndicator: () => ({
              display: 'none',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            dropdownIndicator: () => ({
              display: 'none',
            }),
            input: (provided) => ({
              ...provided,
              padding: '14px',
            }),            
            valueContainer: (provided) => ({
              ...provided,
              padding: '0px',
            }),
            placeholder: (provided) => ({
              ...provided,
              color: '#858585',
              paddingLeft: "14px",
            }),
            menu: (provided) => ({
              ...provided,
              marginTop: '15px',
              display: showOptions ? 'block' : 'none',
            }),
            menuList: (base) => ({
              ...base,
              overflowY: 'auto',
              height: showOptions ? '150px' : 'auto',
              '::-webkit-scrollbar': {
                width: '4px',
                height: '0px',
              },
              '::-webkit-scrollbar-track': {
                background: '#f1f1f1',
              },
              '::-webkit-scrollbar-thumb': {
                background: '#888',
              },
              '::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }),
            option: (provided, { isFocused }) => ({
              ...provided,
              ':hover': {
                backgroundColor: '#F2F6FB',
                color: 'black',
              },
              backgroundColor: isFocused ? '#F2F6FB' : '#ffffff',
              color: isFocused ? 'black' : 'black',
            }),
          },
        }}
      />
    </div>
  );
};
