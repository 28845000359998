import axios from "../axiosConfig";

export const getJobs = (params, callback) => {
  axios
    .post("/job/lookup", params)
    .then(callback)
    .catch((error) => callback({data: {result: false, message: "Error : " + error}}));
}

export const postJob = (params, callback) => {
  axios
    .post("/job/create", params)
    .then(callback)
    .catch((error) => callback({data: {result: false, message: "Error : " + error}}));
}

export const deleteJob = (params, callback) => {
  axios
    .post("/job/delete", params)
    .then(callback)
    .catch((error) => callback({data: {result: false, message: "Error : " + error}}));
}