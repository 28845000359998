export const parseLocation = (addressComponents, value) =>
  addressComponents.find((component) => component?.types?.includes(value))?.long_name || '';

export const parseState = (addressComponents, value) =>
  addressComponents.find((component) => component?.types?.includes(value))?.short_name || '';

export const SORT = [
  { value: 'HighestRating', label: 'Highest Rating' },
  { value: 'LowestRating', label: 'Lowest Rating' },
  { value: 'MostReviews', label: 'Most Reviews' },
  { value: 'LeastReviews', label: 'Least Reviews' },
];
