import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { deleteJob, getJobs, postJob } from "../redux/actions";
import {
  ClickButton,
  InputBox,
  JobBox,
  Label,
} from "../components";

export const JobPage = ({ data }) => {
  const params = useParams();
  const [legal, setLegal] = useState(false);
  const [id, setId] = useState("");
  const [jobs, setJobs] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleDelete = (index) => {
    deleteJob({ _id: jobs[index]._id }, (response) => {
      if (response.data.result) {
        setJobs([...jobs.filter((value, ind) => index !== ind)]);
      } else {
        alert(response.data.message);
      }
    });
  };

  const handlePost = () => {
    postJob({ company: id, title, description }, (response) => {
      if (response.data.result) {
        setJobs([response.data.data, ...jobs]);
      } else {
        alert(response.data.message);
      }
    });
  };

  useEffect(() => {
    setId(data._id);
    let cookie = decodeURIComponent(document.cookie)
      .split(";")
      .find((c) => c.trim().startsWith("lfologin="));
    if (cookie !== null && cookie !== undefined && cookie !== "") {
      cookie = cookie.trim().slice(9);
      if (cookie === "Leavefeedback2024$") setLegal(true);
      else setLegal(data.managers.some((manager) => manager.email === cookie));
    }

    getJobs({ company: data._id }, (response) => {
      if (response.data.result) {
        if (Array.isArray(response.data.data)) {
          setJobs(response.data.data);
        }
      } else {
        console.log(response.data.message);
      }
    });
  }, [params.id]);

  return (
    <>
      {legal && (
        <>
          <Label text="Job Title" />
          <InputBox value={title} func={setTitle} />
          <Label text="Job Description" />
          <InputBox multiline value={description} func={setDescription} />
          <ClickButton onClick={handlePost} text="POST" />
        </>
      )}
      {jobs.map((job, index) => (
        <JobBox
          key={index}
          isManager={legal}
          id={job._id}
          title={job.title}
          description={job.description}
          handleDelete={() => handleDelete(index)}
        />
      ))}
    </>
  );
};
